import { Paper } from '@mui/material';
import { DocumentsListGrid } from './DocumentsListGrid';
import './Documents.scss';
import { DocumentsListProps } from './Documents.types';
import { AchMetadataEditModal } from '../UploadFiles/MetadataEditModal/AchMetadataEditModal';
import { DocumentViewModal } from './DocumentViewModal/DocumentViewModal';
import { DocumentCompareModal } from './DocumentCompareModal/DocumentCompareModal';
import { DocumentFilesCompareModal } from './DocumentFilesCompareModal/DocumentFilesCompareModal';
import { MetadataEditModal } from '../UploadFiles/MetadataEditModal/MetadataEditModal';
import React from 'react';
import { ChatModal } from '../Chat/ChatModal';

export const DocumentsList = ({
  componentsEnabled,
  menuOptions,
  useGetDataListWithParams,
  exportButtonEnabled,
}: DocumentsListProps) => {
  return (
    <Paper elevation={0} className='documents-list'>
      <DocumentsListGrid
        menuOptions={menuOptions}
        useGetDataListWithParams={useGetDataListWithParams}
        exportButtonEnabled={exportButtonEnabled}
      />
      <AchMetadataEditModal useGetDataListWithParams={useGetDataListWithParams} />
      <DocumentViewModal
        useGetDataListWithParams={useGetDataListWithParams}
        componentsEnabled={componentsEnabled}
      />
      <MetadataEditModal addNewVersionMode />
      <DocumentCompareModal />
      <ChatModal />
      <DocumentFilesCompareModal />
    </Paper>
  );
};
