import { MetadataDictionary } from '../../../store/files/upload/list.service.types';

export const transformApprovalStatus = (value: string | undefined) => {
  switch (value) {
    case 'compliance_approved':
      return { IncludeFilters: { ComplianceApproved: true } };
    case 'compliance_declined':
      return { IncludeFilters: { ComplianceApproved: false } };
    case 'legal_approved':
      return { IncludeFilters: { LegalApproved: true } };
    case 'legal_declined':
      return { IncludeFilters: { LegalApproved: false } };
    default:
      return { IncludeFilters: {} };
  }
};

export const APPROVAL_STATUS_DICTIONARY: MetadataDictionary = {
  required: false,
  values: [
    // TODO hidden legal values for now
    { label: 'Approved by Compliance', value: 'compliance_approved' },
    // { label: 'Approved by Legal', value: 'legal_approved' },
    { label: 'Declined by Compliance', value: 'compliance_declined' },
    // { label: 'Declined by Legal', value: 'legal_declined' },
  ],
};
