import {
  LanguageToggleSwitchHookReturn,
  LanguageToggleSwitchTypes,
} from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import {
  DOCUMENT_CONTEXT_MENU_ITEM_NAME,
  DocumentContextMenuItemClick,
} from '../DocumentContextMenu/DocumentContextMenu.types';
import { useEffect, useState } from 'react';
import { useGetComponentsQuery } from '../../../store/files/documents/documents.list.service';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { DOCUMENT_COMPONENTS_REFRESH_INTERVAL } from '../../../config/config';

export const useFetchDocumentComponentsHook = (
  document: DocumentResponse | undefined,
  setLanguage: LanguageToggleSwitchHookReturn[1],
  componentsEnabled?: boolean
) => {
  const [polling, setPolling] = useState(0);

  const { currentData: componentsResponse } = useGetComponentsQuery(document?.DocumentId ?? '', {
    skip: !componentsEnabled || !document?.DocumentId,
    pollingInterval: polling,
  });

  useEffect(() => {
    if (componentsResponse?.components.length === 0) {
      setPolling(DOCUMENT_COMPONENTS_REFRESH_INTERVAL);
    } else if (
      componentsResponse?.components?.length &&
      componentsResponse?.components?.length > 0
    ) {
      setPolling(0);
    }
  }, [componentsResponse?.components.length]);

  const onItemClick: DocumentContextMenuItemClick = (item) => {
    if (item === DOCUMENT_CONTEXT_MENU_ITEM_NAME.SEGMENTATION) {
      setLanguage(LanguageToggleSwitchTypes.Components);
    }
  };

  return {
    onItemClick,
    components: componentsResponse?.components,
    showComponents: componentsEnabled && componentsResponse?.components?.length !== undefined,
  };
};
