import React, { MouseEvent, useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import common from '../../../assets/common.module.scss';
import {
  DocumentComponentContextMenuAction,
  DocumentComponentContextMenuProps,
} from './DocumentComponents.types';

export const DocumentComponentContextMenu = ({ onClick }: DocumentComponentContextMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleContextMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton className='document-component-context-menu' onClick={handleContextMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        className='documents-list-menu'
        sx={{ zIndex: common.zIndexMuiSnackbar }}
        open={anchorEl !== null}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => onClick(DocumentComponentContextMenuAction.SPLIT)}>
          <ContentCutIcon />
          Split
        </MenuItem>
      </Menu>
    </>
  );
};
