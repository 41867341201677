import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import {
  DocumentComponentContextMenuAction,
  DocumentComponentProps,
} from './DocumentComponents.types';
import { DocumentComponentSplit } from './DocumentComponentSplit';
import { DocumentComponentContextMenu } from './DocumentComponentContextMenu';

export const DocumentComponent = ({ component, onConfirm }: DocumentComponentProps) => {
  const [splitMode, setSplitMode] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const handleClick = (action: DocumentComponentContextMenuAction) => {
    if (action === DocumentComponentContextMenuAction.SPLIT) {
      setSplitMode(true);
    }
  };

  return splitMode ? (
    <DocumentComponentSplit
      componentContent={component.componentContent}
      onClose={() => setSplitMode(false)}
      onConfirm={onConfirm}
    />
  ) : (
    <div
      className={'document-component' + (menuVisible ? ' document-component--menu-visible' : '')}
      onMouseEnter={() => setMenuVisible(true)}
      onMouseLeave={() => setMenuVisible(false)}
      role='none'
    >
      <DocumentComponentContextMenu onClick={handleClick} />

      <div
        className='document-component__text'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked.parse(component.componentContent) as string),
        }}
      />
    </div>
  );
};
