import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const DocumentComponentsLoader = () => {
  return (
    <div className='document-components-loader'>
      <CircularProgress />
    </div>
  );
};
