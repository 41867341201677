import React, { useEffect } from 'react';
import './ApprovalValidationFields.scss';
import { Controller } from 'react-hook-form';
import { CustomTextField, requiredRule } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import {
  StateDictionary,
  VALIDATE_FORM_LABELS,
  ApprovalValidationFieldsProps,
} from './ValidateModal.types';
import { BOOLEAN_VALUES } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { InputLabelProps, inputProps } from './ValidateModal';

export const ApprovalValidationFields = ({
  control,
  watch,
  validateReasonField = false,
  trigger,
}: ApprovalValidationFieldsProps) => {
  const isDeclined = watch(ClauseMetadataFields.Approved) === BOOLEAN_VALUES.FALSE;

  useEffect(() => {
    if (isDeclined) {
      trigger?.();
    }
  }, [isDeclined, trigger]);

  return (
    <>
      <div className='reason-dropdown'>
        <Controller
          name={ClauseMetadataFields.Approved}
          control={control}
          rules={requiredRule(validateReasonField, StateDictionary.required)}
          defaultValue={null}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              data-test-id={TEST_ID.VALIDATE_MODAL.STATE_INPUT}
              {...field}
              helperWarning={error}
              select
              label={VALIDATE_FORM_LABELS.STATE}
              values={StateDictionary.values}
            />
          )}
        />
      </div>

      {isDeclined && (
        <div className='comment-wording'>
          <Controller
            name={ClauseMetadataFields.ApprovalComment}
            control={control}
            rules={requiredRule(true, true)}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.VALIDATE_MODAL.STATE_COMMENT_INPUT}
                inputProps={inputProps}
                {...field}
                multiline
                helperWarning={error}
                label=''
                hasCounter
                InputLabelProps={InputLabelProps}
                placeholder={VALIDATE_FORM_LABELS.STATE_COMMENT}
              />
            )}
          />
        </div>
      )}
    </>
  );
};
