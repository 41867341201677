import React from 'react';
import './ClauseItem.scss';
import { Chip } from '@mui/material';
import { AnyAction } from '@reduxjs/toolkit';
import { Clause, ClauseTags } from '../../../store/files/clauses/clauses.list.types';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { useGetClauseDictionariesQueryDefaults } from '../../../store/files/upload/list.service.hooks';
import { useAppDispatch } from '../../../store/hooks';
import { generatePreview } from '../ClausesListGrid.helpers';
import { ClauseItemPreview } from './ClauseItemPreview';
import { ItemNameText } from '../../DocumentsAndClauses/ItemNameText/ItemNameText';
import { mixedMetadata, ProviderChip } from './ClauseItem.helper';
import { ClauseStatusIcon } from '../ClauseStatusIcon/ClauseStatusIcon';
import { ClauseApprovalLabels } from '../ClauseApprovalLabels';

export const ClauseItem = ({
  clause,
  action,
  searchQuery,
  validationLabels,
}: {
  clause: Clause;
  action?: AnyAction;
  searchQuery: string;
  validationLabels?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const handleClick = action ? () => dispatch(action) : undefined;
  const { data: dictionaries } = useGetClauseDictionariesQueryDefaults({
    AccessMode: AccessMode.READ,
  });

  const {
    ClauseName,
    Text,
    Provider,
    Tag,
    ClauseStatus,
    LegalApproved,
    ComplianceApproved,
    LegalApprovedComment,
    ComplianceApprovedComment,
  } = clause;

  return (
    <div className={`clause-item ${action ? 'clause-item-clickable' : ''}`}>
      <div onClick={handleClick}>
        <div className='header'>
          <div className='header-left'>
            <div className='title'>
              <ItemNameText fileName={ClauseName} strong />
            </div>
            {Tag !== ClauseTags.OTHER && <Chip key='tag' label={Tag} className='tag' />}
            <ProviderChip provider={Provider} classname='provider' />
            <ClauseStatusIcon clauseStatus={ClauseStatus} />
          </div>
          <div className='header-right'>
            {validationLabels && (
              <ClauseApprovalLabels
                LegalApproved={LegalApproved}
                ComplianceApproved={ComplianceApproved}
                LegalApprovedComment={LegalApprovedComment}
                ComplianceApprovedComment={ComplianceApprovedComment}
              />
            )}
          </div>
        </div>
        <div className='content-and-metadata'>
          <div
            className='content'
            dangerouslySetInnerHTML={{ __html: generatePreview(Text, searchQuery) }}
          />
          <div className='metadata'>
            {dictionaries && <ClauseItemPreview metadata={mixedMetadata(dictionaries, clause)} />}
          </div>
        </div>
      </div>
    </div>
  );
};
