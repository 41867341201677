import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, ListSubheader, MenuItem, TextField, Tooltip } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { countryCodeEmoji } from 'country-code-emoji';
import { parseISO } from 'date-fns';
import './MetadataFormHelpers.scss';
import {
  DictionariesResponse,
  MetadataDictionary,
  MetadataDictionaryValidationRule,
  MetadataDictionaryValue,
} from '../../../store/files/upload/list.service.types';
import {
  CustomTextFieldProps,
  FormNoticeProps,
  FormWarningProps,
  SearchInputFieldProps,
} from './MetadataFormHelpers.types';
import { UploadedFile, UploadedFileStatus } from '../UploadedFileList.types';
import {
  formatDate,
  isDocumentEditable,
} from '../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { DocumentMetadata, DocumentMetadataFields } from './MetadataForm.types';
import { useSearchInputDict } from '../../StaticComponents/SearchInput/SearchInput.hooks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { API_DATE_FORMAT } from '../../../config/config';
import { FieldPath, UseFormGetFieldState, UseFormSetValue } from 'react-hook-form';
import { ClauseMetadata } from '../../UploadClause/UploadClause.types';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import {
  MenuItemMetadataDictionaryValue,
  SELECT_ALL,
  SELECT_ALL_GERMAN_COMPANIES,
} from './ClauseMetadataForm.types';
import { SetValueType } from '../../AdminPanel/CreateUserPanel/CreateUserPanel.types';
import { getBusinessTypes, getRelatedLobs } from './ClauseMetadataFormHelpers';
import { getGermanEntitiesSubsetFromFilteredValues } from '../../Documents/DocumentsSearch/DocumentFilter.helpers';
import { ClauseNomenclaturePopover } from './ClauseNomenclaturePopover/ClauseNomenclaturePopover';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { getLopOptions } from '../../SpreadSheet/DocumentsGrid/EditAutocomplete.helpers';
import { useSetDefaultField } from './MetadataForm.hooks';
import { useDraftDocumentPrefill } from '../MetadataEditModal/MetadataEditModal.hooks';

export const NOT_SELECTED = 'Not selected';

export enum BOOLEAN_VALUES {
  TRUE = 'True',
  FALSE = 'False',
}

export enum BOOLEAN_OPTIONS {
  YES = 'YES',
  NO = 'NO',
}

export const cleanupLocalFields = (docs: UploadedFile[]): UploadedFile[] =>
  docs.map(({ action, edit, fileType, VersionKey, ...doc }) => {
    if (VersionKey !== NOT_SELECTED) {
      return { ...doc, VersionKey };
    }
    return doc;
  });

export const formatApiDate = (date?: Date | null): string => formatDate(date, API_DATE_FORMAT);

export const formatBoolean = (boolValue?: boolean | null): BOOLEAN_VALUES => {
  return boolValue ? BOOLEAN_VALUES.TRUE : BOOLEAN_VALUES.FALSE;
};

export const parseDate = (date?: string | null): Date | undefined => {
  return date ? parseISO(date) : undefined;
};

export const requiredRule = (validate: boolean, required: boolean) =>
  validate
    ? {
        required: {
          value: required,
          message: UploadedFileStatus.MISSING_DATA,
        },
      }
    : {};

export const minMaxFieldLengthRule = (
  validate: boolean,
  required: boolean,
  min: number,
  max: number
) =>
  validate
    ? {
        required: {
          value: required,
          message: UploadedFileStatus.MISSING_DATA,
        },
        maxLength: {
          value: max,
          message: `Field length should be between ${min} and ${max} `,
        },
        minLength: {
          value: min,
          message: `Field length should be between ${min} and ${max} `,
        },
      }
    : {};

export const apiValidationRule = (
  getFieldState: UseFormGetFieldState<ClauseMetadata>,
  field: FieldPath<ClauseMetadata>,
  inputNameError: string | undefined,
  serverField: string,
  apiErrorMessage: string | undefined
) => ({
  validate: () => {
    return !getFieldState(field).isDirty && inputNameError === serverField
      ? apiErrorMessage
      : undefined;
  },
});

export const cleanupEmpty = (values: DocumentMetadata): DocumentMetadata => {
  let key: keyof DocumentMetadata;
  for (key in values) {
    if (!values[key]) {
      delete values[key];
    }
  }
  return values;
};

export const prepareBulkRequest = (values: DocumentMetadata, documents: UploadedFile[] = []) => {
  const uploadedFile = cleanupEmpty(values);
  const filteredDocs = documents.filter(({ Status }) => isDocumentEditable(Status));
  return filteredDocs.map((doc) => ({ ...doc, ...uploadedFile }));
};

export const FormWarning: React.FC<FormWarningProps> = ({ error }) => {
  return !!error ? (
    <span
      data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_DUPLICATE_INFO}
      className='form-warning'
    >
      <WarningAmberOutlinedIcon /> {error.message}
    </span>
  ) : null;
};

export const FormNotice: React.FC<FormNoticeProps> = ({ tooltipMessage }) => {
  return (
    <span className='form-notice'>
      <Tooltip title={<div className='form-notice-tooltip'>{tooltipMessage}</div>} placement='top'>
        <InfoOutlinedIcon fontSize='inherit' />
      </Tooltip>
    </span>
  );
};

export const DictionaryMenuItem = (
  v: MetadataDictionaryValue,
  filteredValuesValue: string[] = [],
  disabled = false
) => (
  <MenuItem
    key={v.value}
    value={v.value}
    disabled={disabled}
    hidden={!filteredValuesValue.includes(v.value)}
  >
    {v.label}
  </MenuItem>
);

export const CountryMenuItem = (
  v: MetadataDictionaryValue,
  filteredValuesValue: string[] = [],
  disabled = false
) => (
  <MenuItem
    key={v.value}
    value={v.value}
    disabled={disabled}
    hidden={!filteredValuesValue.includes(v.value)}
  >
    {countryCodeEmoji(v.value)} {v.label}
  </MenuItem>
);

export const ParentDocumentMenuItem = (
  v: MenuItemMetadataDictionaryValue,
  filteredValuesValue: string[],
  disabled?: boolean
) => (
  <MenuItem
    key={v.value}
    value={v.value}
    disabled={disabled}
    hidden={!filteredValuesValue.includes(v.value)}
    className='menu-item--parent-documents'
  >
    <span>
      {v.label}
      <small>{v.label2 ? v.label2 : ''}</small>
    </span>
  </MenuItem>
);

export const SearchInputField: React.FC<SearchInputFieldProps> = ({ label, onChangeHandle }) => (
  <ListSubheader value=''>
    <TextField
      onChange={onChangeHandle}
      fullWidth
      size='small'
      autoFocus
      label={`Search for ${label}`}
      onKeyDown={(e) => e.stopPropagation()}
    />
  </ListSubheader>
);

const GroupHeader = (label: string, divider?: boolean) => [
  divider ? <Divider key={`${label}-divider`} /> : undefined,
  <div key={label} className='label'>
    {label}
  </div>,
];

export const CustomTextField: React.FC<CustomTextFieldProps> = React.forwardRef(
  (
    {
      helperWarning,
      tooltipMessage,
      value,
      values,
      isCountry,
      hasCounter,
      hasNamingGuide,
      multiselect,
      groups,
      isParentDocuments,
      selectAll,
      entitiesDictionary,
      variant = 'filled',
      ...props
    },
    ref
  ) => {
    const { filteredValues = [], onChangeSearch } = useSearchInputDict(values ?? []);
    const CustomMenuItem = isParentDocuments
      ? ParentDocumentMenuItem
      : isCountry
      ? CountryMenuItem
      : DictionaryMenuItem;

    const selectableFilteredValues = useMemo(() => {
      return (
        filteredValues?.filter((value: MenuItemMetadataDictionaryValue) => !value.isNonEditable) ||
        []
      );
    }, [filteredValues]);
    const filteredValuesValue = filteredValues.map(({ value }) => value);

    const selectableGermanCompanies = useMemo(() => {
      return getGermanEntitiesSubsetFromFilteredValues(selectableFilteredValues);
    }, [selectableFilteredValues]);

    const mapToMenuItem = useCallback(
      (item: MenuItemMetadataDictionaryValue, filteredValuesValue: string[]) =>
        CustomMenuItem(item, filteredValuesValue, item.isNonEditable ?? item.disabled),
      [CustomMenuItem]
    );

    const menuItems = useMemo(
      () =>
        groups
          ? groups.reduce<(JSX.Element | undefined)[]>((previousValue, label, i) => {
              const items = (values as MenuItemMetadataDictionaryValue[])
                .filter(({ group }) => group === label)
                .map((filteredValue) => mapToMenuItem(filteredValue, filteredValuesValue));

              return [...previousValue, ...GroupHeader(label, i !== 0), ...items];
            }, [])
          : values?.map((filteredValue) => mapToMenuItem(filteredValue, filteredValuesValue)),
      [values, filteredValuesValue, groups, mapToMenuItem]
    );

    return (
      <>
        <TextField
          {...props}
          ref={ref}
          fullWidth
          size='small'
          variant={variant}
          SelectProps={{ multiple: multiselect, displayEmpty: true }}
          value={value}
          helperText={
            <>
              <FormWarning error={helperWarning} />
              <span className='guide-container'>
                {hasNamingGuide && <ClauseNomenclaturePopover />}
                {tooltipMessage && <FormNotice tooltipMessage={tooltipMessage} />}
              </span>
            </>
          }
        >
          <SearchInputField
            label={(props?.label as String)?.toLowerCase()}
            onChangeHandle={onChangeSearch}
          />
          {selectAll &&
            multiselect &&
            selectableFilteredValues &&
            selectableFilteredValues.length > 1 && (
              <MenuItem value={SELECT_ALL}>
                <em>{SELECT_ALL}</em>
              </MenuItem>
            )}
          {selectableGermanCompanies?.length > 1 && (
            <MenuItem value={SELECT_ALL_GERMAN_COMPANIES}>
              <em>{SELECT_ALL_GERMAN_COMPANIES}</em>
            </MenuItem>
          )}
          {menuItems}
        </TextField>

        {hasCounter && value && (
          <div className='custom-text-field-counter'>
            {(value as string).length}/{props?.inputProps?.maxLength}
          </div>
        )}
      </>
    );
  }
);

export const isStringifiedBoolFieldTrue = (value?: string | string[]) =>
  value === BOOLEAN_VALUES.TRUE;

export const useFilterParentDocumentsValues = (
  setValue: SetValueType<DocumentMetadata>,
  selectedParentDocuments: string[] | undefined,
  parentDocuments: { label: string; label2: string; value: string }[] | undefined
) => {
  const [delayedOptions, setDelayedOptions] = useState(parentDocuments);

  useEffect(() => {
    if (selectedParentDocuments && parentDocuments) {
      const indexOfNotSelectedOpt = selectedParentDocuments.indexOf(NOT_SELECTED);
      const isInvalidSelection = selectedParentDocuments.some(
        (doc) => !parentDocuments?.some((option) => option.value === doc)
      );

      const shouldSelectDefaultParentDocumentsOpt = !selectedParentDocuments?.length;

      if (indexOfNotSelectedOpt === 0 && selectedParentDocuments.length > 1) {
        setValue(DocumentMetadataFields.ParentDocuments, selectedParentDocuments.slice(1));
      } else if (
        (indexOfNotSelectedOpt > 0 && selectedParentDocuments.length > 1) ||
        isInvalidSelection ||
        shouldSelectDefaultParentDocumentsOpt
      ) {
        setValue(DocumentMetadataFields.ParentDocuments, [NOT_SELECTED]);
      }

      setDelayedOptions(parentDocuments);
    }
  }, [parentDocuments, selectedParentDocuments, setValue]);

  return delayedOptions;
};

export const useFilterRelatedVersionValue = (
  setValue: SetValueType<DocumentMetadata>,
  selectedRelatedVersionDocument: string | undefined,
  relatedVersionsDocumentsValues: { label: string; label2: string; value: string }[] | undefined
) => {
  const [delayedOptions, setDelayedOptions] = useState(relatedVersionsDocumentsValues);

  useEffect(() => {
    const isInvalidSelection =
      selectedRelatedVersionDocument &&
      !relatedVersionsDocumentsValues?.some((doc) => doc.value === selectedRelatedVersionDocument);

    const shouldSelectDefaultRelatedVersionOpt = relatedVersionsDocumentsValues?.length === 1;

    if (isInvalidSelection || shouldSelectDefaultRelatedVersionOpt) {
      setValue(DocumentMetadataFields.VersionKey, NOT_SELECTED, { shouldValidate: true });
    }

    setDelayedOptions(relatedVersionsDocumentsValues);
  }, [relatedVersionsDocumentsValues, selectedRelatedVersionDocument, setValue]);

  return delayedOptions;
};

export const getValidationRules = (
  dictionary: MetadataDictionary | undefined,
  dictValue: string | undefined,
  type: DocumentMetadataFields,
  value: string,
  related: keyof MetadataDictionaryValidationRule['related']
): string[] => {
  const rules = dictionary?.values.find((dict) => dict.value === dictValue)?.validation_rules;
  return (
    rules
      ?.filter((rule) => rule.type === type && rule.values.includes(value) && rule.related[related])
      .reduce<string[]>((pv, rule) => [...pv, ...(rule.related[related] ?? [])], []) ?? []
  );
};

export const shouldSetDefaultItem = (
  currentValue?: string,
  dictionaryValues?: MetadataDictionaryValue[]
): dictionaryValues is MetadataDictionaryValue[] =>
  (!currentValue || !dictionaryValues?.map(({ value }) => value).includes(currentValue)) &&
  dictionaryValues?.length === 1;

export const useFormFiltering = (
  dictionaries: DictionariesResponse | undefined,
  { Entity, Lob, BusinessType, Lop }: DocumentMetadata,
  setValue: UseFormSetValue<DocumentMetadata>,
  draftPreLandingMode: boolean,
  document?: DocumentMetadata
) => {
  const [relatedLobs, relatedBusinessTypes] = useMemo(() => {
    const entities = Entity ? [Entity] : [];
    return [
      getRelatedLobs(dictionaries?.Entity.values, entities),
      getBusinessTypes(dictionaries?.Entity.values, entities),
    ];
  }, [Entity, dictionaries?.Entity.values]);

  const lobValues = useMemo(
    () =>
      relatedLobs.length
        ? dictionaries?.Lob.values.filter(({ value }) => relatedLobs.includes(value))
        : dictionaries?.Lob.values,
    [dictionaries?.Lob.values, relatedLobs]
  );

  const businessTypesValues = useMemo(() => {
    let businessTypes: string[] = [];
    if (Lob) {
      businessTypes = getValidationRules(
        dictionaries?.Entity,
        Entity,
        DocumentMetadataFields.Lob,
        Lob,
        DocumentMetadataFields.BusinessType
      );
    }
    if (!businessTypes.length) {
      businessTypes = relatedBusinessTypes;
    }
    return businessTypes.length
      ? dictionaries?.BusinessType.values.filter(({ value }) => businessTypes.includes(value))
      : dictionaries?.BusinessType.values;
  }, [Entity, Lob, dictionaries?.BusinessType.values, dictionaries?.Entity, relatedBusinessTypes]);

  const lopValues = useMemo(
    () => (dictionaries ? getLopOptions(dictionaries, Lob, Entity) : []),
    [dictionaries, Entity, Lob]
  );
  useSetDefaultField(setValue, DocumentMetadataFields.Lob, Lob, lobValues, draftPreLandingMode);
  useSetDefaultField(
    setValue,
    DocumentMetadataFields.BusinessType,
    BusinessType,
    businessTypesValues,
    draftPreLandingMode
  );
  useSetDefaultField(setValue, DocumentMetadataFields.Lop, Lop, lopValues, draftPreLandingMode);
  const { handleDraftDocumentPrefill } = useDraftDocumentPrefill(setValue, document);

  useEffect(() => {
    if (draftPreLandingMode) {
      handleDraftDocumentPrefill();
    }
  }, [draftPreLandingMode, handleDraftDocumentPrefill]);

  return { noEntitySelected: !Entity, lobValues, businessTypesValues, lopValues };
};

export const modifyPlaceholder = (params: TextFieldProps, previousVersionValue: string) => {
  const modifiedParams = { ...params };

  if (modifiedParams.inputProps) {
    let value = previousVersionValue === '' ? 'not defined' : previousVersionValue;

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateRegex.test(previousVersionValue)) {
      const [year, month, day] = previousVersionValue.split('-');
      value = `${day}.${month}.${year}`;
    }

    modifiedParams.inputProps.placeholder = `${modifiedParams.inputProps.placeholder} (previous version - ${value})`;
  }

  return modifiedParams;
};
