import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { pick } from 'lodash';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { DocumentMetadata, DocumentMetadataFields } from './MetadataForm.types';
import { getDocumentLabel } from '../../../store/files/documents/documents.list.helpers';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { shouldSetDefaultItem } from './MetadataFormHelpers';

export const useUpdateFormValues = (
  previousVersionDocument: DocumentResponse | undefined,
  document: DocumentMetadata | undefined,
  addNewVersionMode: boolean,
  setValue: UseFormSetValue<DocumentMetadata>,
  setInitialValues: (values: DocumentMetadata) => void
) => {
  const [isInitialSet, setIsInitialSet] = useState(false);
  useEffect(() => {
    if (!isInitialSet && document && addNewVersionMode && previousVersionDocument) {
      const pickedFields = pick(previousVersionDocument, [
        'Entity',
        'Country',
        'Lob',
        'Lop',
        'ProductType',
        'InsurerIndustry',
        'BusinessType',
        'DocumentType',
        'ParentDocuments',
        'Classification',
        'Language',
      ]) as Partial<DocumentMetadata>;

      const mergedValues: DocumentMetadata = {
        ...pickedFields,
        FileName: document?.FileName,
        LatestVersion: 'True',
        Active: 'True',
      };

      (Object.keys(mergedValues) as (keyof DocumentMetadata)[]).forEach((field) => {
        setValue(field, mergedValues[field], { shouldDirty: true });
      });
      setInitialValues(mergedValues);
      setIsInitialSet(true);
    }
  }, [
    previousVersionDocument,
    document,
    addNewVersionMode,
    setValue,
    isInitialSet,
    setInitialValues,
  ]);
};

const fieldsToCheck = [
  DocumentMetadataFields.Lob,
  DocumentMetadataFields.Lop,
  DocumentMetadataFields.ProductType,
  DocumentMetadataFields.InsurerIndustry,
  DocumentMetadataFields.BusinessType,
  DocumentMetadataFields.DocumentType,
];

export const useCheckFieldChanges = (
  initialValues: Partial<DocumentMetadata> | undefined,
  formValues: Partial<DocumentMetadata>
) =>
  useMemo(() => {
    if (initialValues) {
      return fieldsToCheck.filter((field) => initialValues[field] !== formValues[field]);
    }
    return [];
  }, [formValues, initialValues]);

export const useAsyncConfirm = () => {
  const [open, setOpen] = useState(false);
  const [resolveReject, setResolveReject] = useState<{
    resolve: (value: boolean | PromiseLike<boolean>) => void;
    reject: (reason?: unknown) => void;
  } | null>(null);

  const openConfirm = useCallback(() => {
    return new Promise<boolean>((resolve, reject) => {
      setOpen(true);
      setResolveReject({ resolve, reject });
    });
  }, []);

  const handleConfirm = useCallback(() => {
    if (resolveReject) {
      resolveReject.resolve(true);
      setOpen(false);
    }
  }, [resolveReject]);

  const handleClose = useCallback(() => {
    if (resolveReject) {
      resolveReject.resolve(false);
      setOpen(false);
    }
  }, [resolveReject]);

  return { openConfirm, open, handleConfirm, handleClose };
};

export const formatChangedFieldsMessage = (changedFields: DocumentMetadataFields[]) => {
  const labels = changedFields
    .map((field) => getDocumentLabel(field))
    .filter((label) => label !== undefined) as string[];
  const fieldsString =
    labels.length > 1
      ? `${labels.slice(0, -1).join(', ')} and ${labels[labels.length - 1]}`
      : labels[0];

  return `\nThe ${fieldsString} of a new version of a document has been changed and does not correspond with a previous version.\n\n Do you want to continue?`;
};

export const useSetDefaultField = (
  setValue: UseFormSetValue<DocumentMetadata>,
  fieldKey: DocumentMetadataFields,
  currentValue?: string,
  fieldValues?: MetadataDictionaryValue[],
  draftPreLandingMode?: boolean
) => {
  useEffect(() => {
    if (!fieldValues?.length) return;
    const shouldSetDefault = shouldSetDefaultItem(currentValue, fieldValues);
    if (shouldSetDefault || (draftPreLandingMode && !currentValue)) {
      setValue(fieldKey, fieldValues[0].value, { shouldValidate: true });
    }
  }, [currentValue, fieldValues, setValue, fieldKey, draftPreLandingMode]);
};
