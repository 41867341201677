import { ClauseResponse } from '../../store/files/clauses/clauses.list.types';

export type ClauseApprovalLabelsProps = Pick<
  ClauseResponse,
  'LegalApproved' | 'ComplianceApproved' | 'LegalApprovedComment' | 'ComplianceApprovedComment'
>;

export enum ApprovalLabelTypes {
  LEGAL = 'LEGAL',
  COMPLIANCE = 'COMPLIANCE',
}
