import React from 'react';

export enum LanguageToggleSwitchTypes {
  PDF,
  Original,
  English,
  Components,
}

export interface LanguageToggleSwitchParams {
  value: LanguageToggleSwitchTypes;
  onChange: (value: LanguageToggleSwitchTypes) => void;
  pdf?: boolean;
  lockingEnabled?: boolean;
  showComponents?: boolean;
}

export type LanguageToggleSwitchHookReturn = [
  LanguageToggleSwitchTypes,
  React.Dispatch<React.SetStateAction<LanguageToggleSwitchTypes>>,
  boolean?
];
