import React from 'react';
import './DocumentComponents.scss';
import { DocumentComponentsViewerProps } from './DocumentComponents.types';
import { DocumentComponentsList } from './DocumentComponentsList';
import { DocumentComponentsPending } from './DocumentComponentsPending';

export const DocumentComponentsViewer = ({
  documentId,
  components,
}: DocumentComponentsViewerProps) => {
  return (
    <div className='document-components-viewer'>
      {components?.length ? (
        <DocumentComponentsList documentId={documentId} components={components} />
      ) : (
        <DocumentComponentsPending />
      )}
    </div>
  );
};
