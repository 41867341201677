import { DocumentComponentType } from '../../../store/files/documents/documents.list.types';

export interface DocumentComponentsViewerProps {
  documentId: string;
  components?: DocumentComponentType[];
}

export interface DocumentComponentsListProps {
  documentId: string;
  components: DocumentComponentType[];
}

export interface DocumentComponentProps extends SplitProps {
  component: DocumentComponentType;
}

export interface DocumentComponentMergeProps {
  onClick: () => void;
}

export interface DocumentComponentSplitProps extends SplitProps {
  componentContent: string;
  onClose: () => void;
}

export interface DocumentComponentContextMenuProps {
  onClick: (action: DocumentComponentContextMenuAction) => void;
}

export interface HandleSelectorProps {
  componentContent: string;
  onSelect: (offsets?: number[]) => void;
}

interface SplitProps {
  onConfirm: (offsets: number[]) => void;
}

export enum DocumentComponentContextMenuAction {
  SPLIT,
}
