import React, { useEffect, useState } from 'react';
import './ChatModal.scss';
import './Resizer.scss';
import Modal from '@mui/material/Modal';
import { Backdrop } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ModalCloseButton } from '../StaticComponents/Modals/ModalCloseButton';
import { DocumentComparePanel } from '../Documents/DocumentCompareModal/DocumentComparePanel/DocumentComparePanel';
import { documentChatSelectors } from '../../store/files/chat/documentChat.selectors';
import { useGetDictionariesQueryDefaults } from '../../store/files/upload/list.service.hooks';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import SplitPane from 'react-split-pane';
import ChatWindow from './ChatWindow';
import { clearChat, closeChatModal } from '../../store/files/chat/documentChat.slice';
import {
  CLOSE_CHAT_MODAL_CANCEL_BUTTON_LABEL,
  CLOSE_CHAT_MODAL_MESSAGE,
  CLOSE_CHAT_MODAL_SUBMIT_BUTTON_LABEL,
  CLOSE_CHAT_MODAL_TITLE,
} from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { ConfirmationModal } from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal';
import { ChatTermsAndConditions } from './ChatTermsAndConditions';
import { ConfirmationAgreementModal } from '../StaticComponents/Modals/ConfirmationModal/ConfirmationAgreementModal';

const LOCAL_STORAGE_AI_CHAT_AGREEMENT = 'AI_CHAT_AGREEMENT';

export const ChatModal = () => {
  const dispatch = useAppDispatch();
  const chatDocument = useAppSelector(documentChatSelectors.selectModalChat);
  const { data: dictionaries } = useGetDictionariesQueryDefaults({ AccessMode: AccessMode.READ });
  const [confirmation, setConfirmation] = useState(false);
  const [showAIChatAgreementModal, setShowAIChatAgreementModal] = useState(
    localStorage.getItem(LOCAL_STORAGE_AI_CHAT_AGREEMENT) !== 'true'
  );

  useEffect(() => {
    if (chatDocument && localStorage.getItem(LOCAL_STORAGE_AI_CHAT_AGREEMENT) !== 'true') {
      setShowAIChatAgreementModal(true);
    }
  }, [chatDocument]);

  const handleClose = () => {
    setConfirmation(true);
  };

  const handleConfirmationClose = () => {
    setConfirmation(false);
  };

  const handleConfirm = () => {
    dispatch(closeChatModal());
    dispatch(clearChat());
  };

  if (!chatDocument) {
    return null;
  }

  return (
    <>
      <Modal
        open={!!chatDocument && !showAIChatAgreementModal}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 0,
        }}
        classes={{
          root: 'chat-modal-root',
        }}
      >
        <div className='chat-modal'>
          <SplitPane
            split='vertical'
            minSize={330}
            maxSize={window.innerWidth - 330}
            defaultSize='67%'
            style={{ height: '100%', position: 'relative' }}
          >
            <div className='chat-pane'>
              <DocumentComparePanel
                document={chatDocument}
                documentDictionaries={dictionaries}
                chatMode
                searchOption
              />
            </div>
            <div className='chat-pane'>
              <ChatWindow documentId={chatDocument.DocumentId} />
            </div>
          </SplitPane>

          <ModalCloseButton onClose={handleClose} tooltip='Close chat window' />
        </div>
      </Modal>

      <ConfirmationAgreementModal
        className='chat-agreement-modal'
        onClose={() => {
          dispatch(closeChatModal());
          setShowAIChatAgreementModal(false);
        }}
        onConfirm={() => {
          localStorage.setItem(LOCAL_STORAGE_AI_CHAT_AGREEMENT, 'true');
          setShowAIChatAgreementModal(false);
        }}
        message={ChatTermsAndConditions}
        label='I agree to the terms and conditions'
        open={showAIChatAgreementModal}
        successButtonLabel='Confirm'
        title='GWR AI Assistant'
        cancelButtonLabel='Return'
      />

      <ConfirmationModal
        open={confirmation}
        onClose={handleConfirmationClose}
        onConfirm={handleConfirm}
        title={CLOSE_CHAT_MODAL_TITLE}
        message={CLOSE_CHAT_MODAL_MESSAGE}
        cancelButtonLabel={CLOSE_CHAT_MODAL_CANCEL_BUTTON_LABEL}
        successButtonLabel={CLOSE_CHAT_MODAL_SUBMIT_BUTTON_LABEL}
      />
    </>
  );
};
