import React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import {
  LanguageToggleSwitchParams,
  LanguageToggleSwitchTypes,
} from './LanguageToggleSwitch.types';
import './LanguageToggleSwitch.scss';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { useAppSelector } from '../../../store/hooks';

export const LanguageToggleSwitch = ({
  value,
  onChange,
  pdf,
  lockingEnabled,
  showComponents,
}: LanguageToggleSwitchParams) => {
  const isLanguageLocked = useAppSelector(uiSelectors.selectLockLanguage);
  const lockedLanguage = useAppSelector(uiSelectors.selectLockedLanguage);
  const originalLanguageLocked = lockingEnabled && isLanguageLocked;

  return (
    <ToggleButtonGroup
      exclusive
      value={!originalLanguageLocked ? value : lockedLanguage}
      onChange={(_e, v) => v !== null && onChange(v)}
      color='primary'
      size='small'
      className='buttons-tabs'
    >
      {pdf && <ToggleButton value={LanguageToggleSwitchTypes.PDF}>PDF</ToggleButton>}
      <ToggleButton value={LanguageToggleSwitchTypes.Original}>Original text</ToggleButton>
      <ToggleButton value={LanguageToggleSwitchTypes.English}>English text</ToggleButton>
      {showComponents && (
        <ToggleButton value={LanguageToggleSwitchTypes.Components}>Components</ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};
