import { API_URL } from '../../../config/envs';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { DocumentComponentsAdjustRequest } from './documents.list.types';

let component_id = '';
let component_count = 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryType = (req: any) => { url: string; method: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const response = (query: QueryType, req: any, code: number, data?: any) => {
  const { method, url: fullUrl, ...body } = query(req);
  const url = fullUrl.replace(API_URL, '');
  const error = code >= 400;

  const methodColor = method === 'GET' ? 'lightblue' : 'lightgreen';
  const codeColor = !error ? 'green' : 'red';

  // eslint-disable-next-line no-console
  console.log(`%c${method} ${url}: %c${code}`, 'color: ' + methodColor, 'color: ' + codeColor);
  // eslint-disable-next-line no-console
  console.log(JSON.stringify(body, undefined, 2));

  const response = { status: code, data };
  return !error ? response : { error: response };
};

const components = (id: string) => [
  {
    componentId: 'id0',
    componentStatus: 'irrelevant',
    componentContent: 'document title ' + id,
  },
  {
    componentId: 'id1',
    componentStatus: 'relevant',
    componentContent:
      '# 12.1\nSie verlieren **Ihren** Versicherungsschutz, wenn *Sie* die\ngenannten Obliegenheiten vorsätzlich verletzen.',
  },
  {
    componentId: 'id2',
    componentStatus: 'relevant',
    componentContent:
      '12.2\nBei grober Fahrlässigkeit können wir die Leistung ent-\nsprechend der Schwere des Verschuldens kürzen.\nDies gilt nicht, wenn Sie nachweisen, dass Sie die\nObliegenheiten nicht grob fahrlässig verletzt haben.',
  },
  {
    componentId: 'id3',
    componentStatus: 'relevant',
    componentContent:
      '12.3\nVerletzen Sie eine nach Eintritt des Versicherungsfalls\nbestehende Auskunfts- und Aufklärungsobliegen-\nheit, kann der Versicherungsschutz vollständig oder\nteilweise entfallen. Voraussetzung hierfür ist, dass\nwir Sie mit einer gesonderten Mitteilung in Textform\nauf diese Folge hingewiesen haben.',
  },
  {
    componentId: 'id4',
    componentStatus: 'relevant',
    componentContent:
      '12.1\nSie verlieren Ihren Versicherungsschutz, wenn Sie die\ngenannten Obliegenheiten vorsätzlich verletzen.',
  },
  {
    componentId: 'id5',
    componentStatus: 'relevant',
    componentContent:
      '12.2\nBei grober Fahrlässigkeit können wir die Leistung ent-\nsprechend der Schwere des Verschuldens kürzen.\nDies gilt nicht, wenn Sie nachweisen, dass Sie die\nObliegenheiten nicht grob fahrlässig verletzt haben.',
  },
  {
    componentId: 'id6',
    componentStatus: 'relevant',
    componentContent:
      '12.3\n\nVerletzen Sie eine nach Eintritt des Versicherungsfalls bestehende Auskunfts- und Aufklärungsobliegenheit, kann der Versicherungsschutz vollständig oder teilweise entfallen. Voraussetzung hierfür ist, dass wir Sie mit einer gesonderten Mitteilung in Textform auf diese Folge hingewiesen haben.',
  },
];

export const queryFnGet = (query: QueryType) => {
  return (id: string) => {
    if (id === component_id) {
      if (component_count < 4) {
        component_count += 1;
        return response(query, id, 202, { timestamp: 'timestamp', components: [] });
      }
      return response(query, id, 200, { timestamp: 'timestamp', components: components(id) });
    }
    return response(query, id, 404);
  };
};

export const queryFnGenerate = (query: QueryType) => {
  return async (id: string) => {
    await timeoutPromise(2000);

    if (Math.random() < 0.1) {
      return response(query, id, 500);
    }

    component_id = id;
    component_count = 0;

    return response(query, id, 200);
  };
};

export const queryFnAdjust = (query: QueryType) => {
  return async (req: DocumentComponentsAdjustRequest) => {
    await timeoutPromise(2000);

    if (Math.random() < 0.3) {
      return response(query, req, 500);
    }

    return response(query, req, 200);
  };
};
