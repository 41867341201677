import { ApprovalLabel } from '../StaticComponents/Labels/ApprovalLabel';
import { ApprovalLabelTypes, ClauseApprovalLabelsProps } from './ClauseApprovalLabels.types';

export const ClauseApprovalLabels = ({
  LegalApproved,
  ComplianceApproved,
  LegalApprovedComment,
  ComplianceApprovedComment,
}: ClauseApprovalLabelsProps) => {
  return (
    <>
      {LegalApproved !== '' && (
        <ApprovalLabel
          approved={LegalApproved}
          label={ApprovalLabelTypes.LEGAL}
          comment={LegalApprovedComment}
        />
      )}
      {ComplianceApproved !== '' && (
        <ApprovalLabel
          approved={ComplianceApproved}
          label={ApprovalLabelTypes.COMPLIANCE}
          comment={ComplianceApprovedComment}
        />
      )}
    </>
  );
};
