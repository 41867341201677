import React, { useEffect, useMemo } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import {
  apiValidationRule,
  CustomTextField,
  formatBoolean,
  requiredRule,
} from './MetadataFormHelpers';

import {
  CLAUSE_LABELS,
  CLAUSE_METADATA_TOOLTIPS,
  CLAUSE_STATUS_TOOLTIPS,
  DOCUMENT_LABELS,
} from '../../../config/config';
import { useGetClauseDictionariesQueryDefaults } from '../../../store/files/upload/list.service.hooks';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { CLAUSE_SOURCE_ERGO_INDIVIDUAL } from '../../../store/files/upload/list.helpers';
import { ClauseMetadata, ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import { ClauseMetadataFormProps } from './ClauseMetadataForm.types';
import {
  extractSelectValue,
  getRelatedCountries,
  handleNonEditableDictValues,
  handleSpecialSelections,
  mapLopValues,
  updateBusinessTypes,
  updateLobs,
  updateLops,
  useFormDropdownData,
  useFormFiltering,
} from './ClauseMetadataFormHelpers';
import { useGetClauseTagsQuery } from '../../../store/files/clauses/clauses.list.service';
import { ClauseStatuses } from '../../../store/files/clauses/clauses.list.types';
import { CustomCheckbox } from './ClauseMetadataForm.helpers';
import './ClauseMetadataForm.scss';
import { getRoutePath, RoutePath } from '../../../services/route.service';
import { useLocation } from 'react-router-dom';
import {
  ApprovalValidationFieldsControl,
  ApprovalValidationFieldsWatch,
} from '../../Clauses/ValidateModal/ValidateModal.types';
import { useUserAccessContext } from '../../../contexts/UserAccess';
import { ApprovalValidationFields } from '../../Clauses/ValidateModal/ApprovalValidationFields';

const validate = true;

export const ClauseMetadataForm = ({
  formMethods,
  disableFromMetadataEdit,
  apiErrorMessage,
  inputNameError,
  clause,
  baseDocumentForClauseCreation,
  onUpdateEntities,
  inheritedMetadata,
  inheritedMetadataIsLoading,
  disableTags,
  adjustedClause,
  draft,
}: ClauseMetadataFormProps) => {
  const { control, watch, trigger, setValue, getFieldState, getValues } = formMethods;

  const { pathname } = useLocation();
  const { data: dictionaries } = useGetClauseDictionariesQueryDefaults({
    AccessMode: AccessMode.WRITE,
    Draft: formatBoolean(
      draft ||
        pathname === getRoutePath(RoutePath.SANDBOX_CLAUSES, RoutePath.UPLOADED_CLAUSES_LIST_VIEW)
    ),
  });
  const { data: readAccessDictionaries } = useGetClauseDictionariesQueryDefaults({
    AccessMode: AccessMode.READ,
  });

  const clauseSources = watch().ClauseSources;
  const isClauseSourceErgoIndividual = Array.isArray(clauseSources)
    ? clauseSources.includes(CLAUSE_SOURCE_ERGO_INDIVIDUAL)
    : false;

  const selectedEntity = watch(ClauseMetadataFields.Entities);

  const { noEntitySelected, lobValues, businessTypesValues } = useFormFiltering(
    readAccessDictionaries,
    getValues(),
    setValue
  );
  const lopValues = useMemo(() => mapLopValues(dictionaries), [dictionaries]);

  const { Lobs, Lops, BusinessTypes, ClauseStatus } = getValues();

  const {
    nonEditableEntities,
    mergedEntitiesValues,
    entitiesGroups,
    mergedCountriesValues,
    mergedLobValues,
    lobsGroups,
    mergedLopValues,
    lopsGroups,
    mergedBusinessTypesValues,
    businessTypesGroups,
  } = useFormDropdownData(
    Lobs,
    lopValues,
    lobValues,
    businessTypesValues,
    clause,
    readAccessDictionaries,
    dictionaries,
    baseDocumentForClauseCreation,
    inheritedMetadata
  );

  const { hasValidateModalAccess } = useUserAccessContext();

  const { data: clauseTags = [] } = useGetClauseTagsQuery(undefined, { skip: disableTags });

  useEffect(() => {
    const entityValues = dictionaries?.Entities.values;
    if (entityValues?.length !== 1 || selectedEntity?.length) return;

    setValue(ClauseMetadataFields.Entities, [entityValues[0].value]);

    trigger();
  }, [trigger, dictionaries, setValue, selectedEntity]);

  useEffect(() => {
    if (!readAccessDictionaries) return;

    const entityValues = readAccessDictionaries?.Entities.values;

    if ((entityValues?.length || 0) > 1 && selectedEntity) {
      const entitiesArray = Array.isArray(selectedEntity) ? selectedEntity : [selectedEntity];

      const relatedCountries = getRelatedCountries(entitiesArray, entityValues);

      setValue(ClauseMetadataFields.Countries, relatedCountries);
      trigger(ClauseMetadataFields.Countries);
    }
  }, [readAccessDictionaries, setValue, selectedEntity, trigger]);

  useEffect(() => {
    if (onUpdateEntities) {
      onUpdateEntities(nonEditableEntities);
    }
  }, [nonEditableEntities, onUpdateEntities]);

  useEffect(() => {
    trigger();
  }, [trigger, ClauseStatus]);

  const changeClauseStatus = (
    field: ControllerRenderProps<ClauseMetadata, ClauseMetadataFields.ClauseStatus>,
    status: ClauseStatuses
  ) => {
    field.value === status ? field.onChange(ClauseStatuses.OTHER) : field.onChange(status);
    setValue(ClauseMetadataFields.Remarks, '');
    trigger();
  };

  useEffect(() => {
    if (dictionaries && readAccessDictionaries) {
      trigger();
    }
  }, [dictionaries, readAccessDictionaries, trigger]);

  if (!dictionaries || !readAccessDictionaries || inheritedMetadataIsLoading)
    return (
      <div className='metadata-form-spinner'>
        <Box sx={{ textAlign: 'center', padding: '30px' }}>
          <CircularProgress />
        </Box>
      </div>
    );

  const RemarksField = (
    <Controller
      control={control}
      name={ClauseMetadataFields.Remarks}
      defaultValue=''
      rules={requiredRule(
        validate,
        [ClauseStatuses.INVALID, ClauseStatuses.BEST_PRACTICE].includes(ClauseStatus)
      )}
      render={({ field, fieldState: { error } }) => (
        <CustomTextField
          {...field}
          value={field.value ?? ''}
          helperWarning={error}
          label={DOCUMENT_LABELS.REMARKS}
        />
      )}
    />
  );

  return (
    <>
      <Controller
        control={control}
        name={ClauseMetadataFields.ClauseName}
        rules={{
          ...requiredRule(validate, true),
          ...apiValidationRule(
            getFieldState,
            'ClauseName',
            inputNameError,
            'ClauseName',
            apiErrorMessage
          ),
        }}
        defaultValue=''
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            helperWarning={error}
            label={CLAUSE_LABELS.CLAUSE_NAME}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.CLAUSE_NAME}
            hasNamingGuide
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.Entities}
        rules={requiredRule(validate, dictionaries?.Entities?.required)}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            onChange={(e) => {
              field.onChange(e);
              const values = extractSelectValue(e);
              handleSpecialSelections(
                values,
                setValue,
                ClauseMetadataFields.Entities,
                mergedEntitiesValues
              );
              const lobs = updateLobs(readAccessDictionaries, values, Lobs, setValue);
              updateLops(dictionaries, lobs, Lops, setValue);
              updateBusinessTypes(dictionaries, values, BusinessTypes, setValue);
            }}
            groups={entitiesGroups}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.COMPANY_NAME}
            select
            disabled={dictionaries?.Entities.values.length < 2 || !!adjustedClause}
            multiselect
            selectAll
            entitiesDictionary={dictionaries?.Entities}
            label={DOCUMENT_LABELS.COMPANY_NAME}
            values={mergedEntitiesValues}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.Lobs}
        rules={requiredRule(validate, dictionaries?.Lobs?.required)}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            disabled={noEntitySelected || !!adjustedClause}
            groups={lobsGroups}
            onChange={(e) => {
              field.onChange(e);
              const values = extractSelectValue(e);
              handleSpecialSelections(values, setValue, ClauseMetadataFields.Lobs, mergedLobValues);
              updateLops(dictionaries, values, Lops, setValue);
            }}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.BUSINESS_LINE}
            select
            multiselect
            selectAll
            label={DOCUMENT_LABELS.BUSINESS_LINE}
            values={mergedLobValues}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.Lops}
        rules={{
          ...requiredRule(validate, dictionaries?.Lops?.required),
          ...apiValidationRule(getFieldState, 'Lops', inputNameError, 'Lop', apiErrorMessage),
        }}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            onChange={(e) => {
              field.onChange(e);
              const values = extractSelectValue(e);
              handleSpecialSelections(values, setValue, ClauseMetadataFields.Lops, mergedLopValues);
            }}
            disabled={!Lobs?.length || !!adjustedClause}
            groups={lopsGroups}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.PRODUCT_LINE}
            select
            multiselect
            selectAll
            label={DOCUMENT_LABELS.PRODUCT_LINE}
            values={mergedLopValues}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.Countries}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.COUNTRY}
            label={DOCUMENT_LABELS.COUNTRY}
            select
            disabled
            multiselect
            values={mergedCountriesValues}
            isCountry
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.BusinessTypes}
        rules={requiredRule(validate, dictionaries?.BusinessTypes?.required)}
        defaultValue={[]}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            disabled={noEntitySelected || !!adjustedClause}
            groups={businessTypesGroups}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.BUSINESS_TYPE}
            select
            multiselect
            label={DOCUMENT_LABELS.BUSINESS_TYPE}
            values={mergedBusinessTypesValues}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.Classification}
        rules={requiredRule(validate, dictionaries?.Classification?.required)}
        defaultValue=''
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.CLASSIFICATION}
            label={DOCUMENT_LABELS.CLASSIFICATION}
            select
            values={dictionaries?.Classification.values}
            disabled={!!adjustedClause}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.Language}
        rules={{
          ...requiredRule(validate, dictionaries?.Language?.required),
          ...apiValidationRule(
            getFieldState,
            'Language',
            inputNameError,
            'Language',
            apiErrorMessage
          ),
        }}
        defaultValue=''
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.LANGUAGE}
            label={DOCUMENT_LABELS.ORIGINAL_LANGUAGE}
            select
            values={dictionaries?.Language.values}
            disabled={disableFromMetadataEdit || !!adjustedClause}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.ClauseSources}
        defaultValue={[]}
        rules={requiredRule(validate, dictionaries?.ClauseSources.required)}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            onChange={(e) => {
              field.onChange(e);
              const isEISelected = e.target.value.includes(CLAUSE_SOURCE_ERGO_INDIVIDUAL);
              if (!isEISelected) {
                setValue(ClauseMetadataFields.IndividualizationReason, '', {
                  shouldValidate: true,
                });
              }
              trigger();
            }}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.SOURCE}
            select
            multiselect
            label={CLAUSE_LABELS.CLAUSE_SOURCES}
            values={dictionaries?.ClauseSources?.values}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.IndividualizationReason}
        defaultValue=''
        rules={requiredRule(
          validate,
          dictionaries?.IndividualizationReason?.required || isClauseSourceErgoIndividual
        )}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.REASON}
            label={CLAUSE_LABELS.REASONS_FOR_INDIVIDUALIZATION}
            disabled={!isClauseSourceErgoIndividual}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.ClauseTypes}
        defaultValue={[]}
        rules={requiredRule(validate, dictionaries?.ClauseTypes.required)}
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.TYPE}
            select
            multiselect
            label={CLAUSE_LABELS.TYPE_OF_CLAUSE}
            values={handleNonEditableDictValues(
              dictionaries?.ClauseTypes.values,
              adjustedClause?.ClauseTypes
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={ClauseMetadataFields.ClauseNumber}
        rules={requiredRule(validate, dictionaries?.ClauseNumber?.required)}
        defaultValue=''
        render={({ field, fieldState: { error } }) => (
          <CustomTextField
            {...field}
            helperWarning={error}
            tooltipMessage={CLAUSE_METADATA_TOOLTIPS.NUMBER}
            label={CLAUSE_LABELS.CLAUSE_NUMBER}
            disabled={disableFromMetadataEdit}
          />
        )}
      />

      {hasValidateModalAccess && (
        <ApprovalValidationFields
          control={control as ApprovalValidationFieldsControl}
          watch={watch as ApprovalValidationFieldsWatch}
          trigger={trigger}
          validateReasonField
        />
      )}

      <Controller
        control={control}
        name={ClauseMetadataFields.ClauseStatus}
        rules={requiredRule(validate, dictionaries?.ClauseStatus?.required)}
        defaultValue={ClauseStatuses.OTHER}
        render={({ field }) => (
          <CustomCheckbox
            checked={field.value === ClauseStatuses.BEST_PRACTICE}
            onChange={() => changeClauseStatus(field, ClauseStatuses.BEST_PRACTICE)}
            label={CLAUSE_LABELS.CLAUSE_SOURCE_BEST_PRACTICE}
            tooltipMessage={CLAUSE_STATUS_TOOLTIPS.BEST_PRACTICE_EDIT}
            disabled={field.value === ClauseStatuses.INVALID}
          />
        )}
      />

      {ClauseStatus === ClauseStatuses.BEST_PRACTICE ? RemarksField : null}

      <Controller
        control={control}
        name={ClauseMetadataFields.ClauseStatus}
        rules={requiredRule(validate, dictionaries?.ClauseStatus?.required)}
        defaultValue={ClauseStatuses.OTHER}
        render={({ field }) => (
          <CustomCheckbox
            checked={field.value === ClauseStatuses.INVALID}
            onChange={() => changeClauseStatus(field, ClauseStatuses.INVALID)}
            label={CLAUSE_LABELS.CLAUSE_SOURCE_INVALID}
            tooltipMessage={CLAUSE_STATUS_TOOLTIPS.INVALID_EDIT}
            disabled={field.value === ClauseStatuses.BEST_PRACTICE}
          />
        )}
      />

      {ClauseStatus === ClauseStatuses.INVALID ? RemarksField : null}

      {!disableTags && (
        <Controller
          control={control}
          name={ClauseMetadataFields.Tag}
          defaultValue=''
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              {...field}
              helperWarning={error}
              tooltipMessage={CLAUSE_METADATA_TOOLTIPS.TAG}
              select
              label={ClauseMetadataFields.Tag}
              values={clauseTags}
            />
          )}
        />
      )}
    </>
  );
};
