import { v4 as uuidv4 } from 'uuid';
import {
  DocumentComponentAdjustActionType,
  DocumentComponentsAdjustRequest,
  DocumentComponentStatus,
  DocumentComponentType,
  DOCUMENTS_LIST_API_ENDPOINTS,
  DOCUMENTS_LIST_API_TAGS,
} from '../../../store/files/documents/documents.list.types';
import { documentsListApi } from '../../../store/files/documents/documents.list.service';
import { getOriginalArgs } from '../DocumentContextMenu/DocumentContextMenuDelete.helpers';

const getNewComponent = (componentContent: string) => ({
  componentId: uuidv4(),
  componentContent,
  componentStatus: DocumentComponentStatus.RELEVANT,
});

export const getMergedComponents = (
  components: DocumentComponentType[],
  id: DocumentComponentType['componentId']
) => {
  const component = components.find(({ componentId }) => componentId === id);
  const componentIndex = components.findIndex(({ componentId }) => componentId === id);
  const nextComponent = components[componentIndex + 1];

  if (!component || !nextComponent) throw new Error('Component not found');

  const newComponent: DocumentComponentType = getNewComponent(
    component.componentContent + nextComponent.componentContent
  );

  const adjustActions: DocumentComponentsAdjustRequest['body'] = [
    {
      actionType: DocumentComponentAdjustActionType.ADD,
      payload: { ...newComponent, position: componentIndex.toString() },
    },
    {
      actionType: DocumentComponentAdjustActionType.DELETE,
      payload: { componentId: component.componentId },
    },
    {
      actionType: DocumentComponentAdjustActionType.DELETE,
      payload: { componentId: nextComponent.componentId },
    },
  ];

  const newComponents = components
    .map((component) => (component.componentId === id ? newComponent : component))
    .filter((component) => component.componentId !== nextComponent.componentId);

  return { adjustActions, newComponents };
};

export const getSplitComponents = (
  components: DocumentComponentType[],
  id: DocumentComponentType['componentId'],
  offsets: number[]
) => {
  const component = components.find(({ componentId }) => componentId === id);
  const componentIndex = components.findIndex(({ componentId }) => componentId === id);

  if (!component) throw new Error('Component not found');

  const splitComponents = [
    component.componentContent.slice(0, offsets[0]),
    component.componentContent.slice(offsets[0], offsets[1]),
    component.componentContent.slice(offsets[1]),
  ]
    .filter(Boolean)
    .map<DocumentComponentType>(getNewComponent);

  const adjustActions: DocumentComponentsAdjustRequest['body'] = [
    ...splitComponents.map((newComponent, index) => ({
      actionType: DocumentComponentAdjustActionType.ADD,
      payload: { ...newComponent, position: (componentIndex + index).toString() },
    })),
    {
      actionType: DocumentComponentAdjustActionType.DELETE,
      payload: { componentId: component.componentId },
    },
  ];

  const newComponents = components.flatMap((component) =>
    component.componentId === id ? splitComponents : [component]
  );

  return { adjustActions, newComponents };
};

export const updateDocumentComponents = (components: DocumentComponentType[]) =>
  documentsListApi.util.updateQueryData(
    DOCUMENTS_LIST_API_ENDPOINTS.GET_COMPONENTS,
    getOriginalArgs(
      DOCUMENTS_LIST_API_TAGS.DOCUMENT_COMPONENTS,
      DOCUMENTS_LIST_API_ENDPOINTS.GET_COMPONENTS
    ),
    (cachedComponentsResponse) => {
      cachedComponentsResponse.components = components;
    }
  );
