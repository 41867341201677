import React, { useState } from 'react';
import { DocumentComponentsListProps } from './DocumentComponents.types';
import { useAppDispatch } from '../../../store/hooks';
import { useSnackbar } from 'notistack';
import { useAdjustComponentsMutation } from '../../../store/files/documents/documents.list.service';
import {
  getMergedComponents,
  getSplitComponents,
  updateDocumentComponents,
} from './DocumentComponentsList.helpers';
import { ErrorMessages } from '../../../services/errors.service.types';
import { DocumentComponent } from './DocumentComponent';
import { DocumentComponentMerge } from './DocumentComponentMerge';
import { DocumentComponentsLoader } from './DocumentComponentsLoader';

export const DocumentComponentsList = ({ documentId, components }: DocumentComponentsListProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [adjust] = useAdjustComponentsMutation();

  const handleConfirm = (componentId: string) => async (offsets: number[]) => {
    setLoading(true);
    try {
      const { adjustActions, newComponents } = getSplitComponents(components, componentId, offsets);
      await adjust({ id: documentId, body: adjustActions }).unwrap();
      dispatch(updateDocumentComponents(newComponents));
    } catch (e) {
      enqueueSnackbar(ErrorMessages.ComponentMergeError, { variant: 'error' });
    }
    setLoading(false);
  };

  const handleClick = (componentId: string) => async () => {
    setLoading(true);
    try {
      const { adjustActions, newComponents } = getMergedComponents(components, componentId);
      await adjust({ id: documentId, body: adjustActions }).unwrap();
      dispatch(updateDocumentComponents(newComponents));
    } catch (e) {
      enqueueSnackbar(ErrorMessages.ComponentMergeError, { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <div className='document-components-list'>
      {components.map((component) => (
        <React.Fragment key={component.componentId}>
          <DocumentComponent
            component={component}
            onConfirm={handleConfirm(component.componentId)}
          />
          <DocumentComponentMerge onClick={handleClick(component.componentId)} />
        </React.Fragment>
      ))}

      {loading && <DocumentComponentsLoader />}
    </div>
  );
};
