import { UploadedFileStatus } from '../../UploadFiles/UploadedFileList.types';
import { GridInputSelectionModel } from '@mui/x-data-grid-pro';
import { Document } from '../../../store/files/documents/documents.list.types';
import { DocumentListDataFetchingHook } from '../../Documents/Documents.types';

export interface DocumentGridProps {
  setDataChanged?: (dataChanged: SpreadSheetDocument[]) => void;
  search: string;
  docs: SpreadSheetDocument[];
  isEditingDisabled?: boolean;
  validate?: boolean;
  updateStatuses?: DocumentUpdateStatuses;
  isLoading: boolean;
  rowCount?: number;
  page?: number;
  pageSize: number;
  className?: string;
  setPage?: (value: number) => void;
  setPageSize?: (value: number) => void;
  selectionModel?: GridInputSelectionModel;
  onSelectionModelChange: (selectionModel: GridInputSelectionModel) => void;
  disabledColumns?: DOCUMENTS_GRID_COLUMNS[];
  isSnackbarEnabled?: boolean;
}

export type DocumentGridWrapperProps = Pick<DocumentGridProps, 'search' | 'isSnackbarEnabled'>;

export type AchDocumentGridWrapperProps = DocumentGridWrapperProps & {
  useGetDataListWithParams: DocumentListDataFetchingHook;
};

export interface SpreadSheetDocument {
  DocumentId: string;
  FileName: string;
  Entity?: string;
  Country?: string;
  Lob?: string;
  Lop?: string;
  BusinessType?: string;
  DocumentType?: string;
  Classification?: string;
  Language?: string;
  Active?: string;
  ValidFrom?: string;
  ValidTo?: string;
  ExpireAt?: string;
  Version?: string;
  LatestVersion?: string;
  Status?: UploadedFileStatus;
  DocumentName?: string;
  UpdatedAt?: string;
  RelatedClauses?: string[];
}

export enum METADATA_KEYS {
  // as any is workaround to get enum key by value
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  ENTITY = 'Entity' as any,
  COUNTRY = 'Country' as any,
  LOB = 'Lob' as any,
  LOP = 'Lop' as any,
  BUSINESS_TYPE = 'BusinessType' as any,
  DOCUMENT_TYPE = 'DocumentType' as any,
  CLASSIFICATION = 'Classification' as any,
  LANGUAGE = 'Language' as any,
  LATEST_VERSION = 'LatestVersion' as any,
  /* eslint-enable  @typescript-eslint/no-explicit-any */
}

export enum DOCUMENTS_GRID_COLUMNS {
  DOCUMENT_ID = 'DocumentId',
  FILE_NAME = 'FileName',
  COMPANY_NAME = 'Entity',
  COUNTRY = 'Country',
  BUSINESS_LINE = 'Lob',
  PRODUCT_TYPE = 'ProductType',
  INSURER_INDUSTRY = 'InsurerIndustry',
  PRODUCT_LINE = 'Lop',
  BUSINESS_TYPE = 'BusinessType',
  DOCUMENT_TYPE = 'DocumentType',
  CLASSIFICATION = 'Classification',
  LANGUAGE = 'Language',
  ACTIVE = 'Active',
  VALID_FROM = 'ValidFrom',
  VALID_TO = 'ValidTo',
  EXPIRE_AT = 'ExpireAt',
  VERSION = 'Version',
  LATEST_VERSION = 'LatestVersion',
  DOCUMENT_NAME = 'DocumentName',
  STATUS = 'Status',
  ERRORS = 'Errors',
}

export type DocumentUpdateStatuses = Record<Document['DocumentId'], string>;

export enum DocumentUpdateDescriptions {
  ProcessingTaskActive = 'There is already one existing task scheduled to process the metadata update.',
  SuccessfullyUpdated = 'Successfully updated',
  UnsavedChanges = 'Unsaved changes',
  DocumentNotExist = 'Document not found',
  MissingMandatoryFields = 'Missing mandatory fields{fields}',
  InvalidValue = 'Invalid value for field "{field}"',
  InvalidParamName = 'Incorrect parameter name provided',
  InvalidDateOrder = 'Incorrect date order provided',
  InvalidFormat = 'Incorrect format of field "{field}"',
  InvalidColumn = 'Provided column "{field}" does not exist in database',
  InvalidFileNameFormat = 'Invalid characters found in file name',
  InvalidScanningReport = 'Invalid scanning report',
  InvalidTypeOfFile = 'Invalid type of file',
  IncorrectDocumentStatus = 'Incorrect document status',
  MissingFields = 'Missing fields',
  IncorrectHeaders = 'Incorrect headers',
  FileEmptyOrCorrupted = 'File empty or corrupted',
  NoPermissions = 'No permissions to change field "{field}"',
  NoWritePermissions = 'No write permissions',
  NoPermissionToModifyFiles = 'No permissions to modify files',
  NotAllowedSizeOfFile = 'Not allowed size of file',
  NumberOfFilesNotAllowed = 'Number of files not allowed',
  ProtectedFileByPassword = 'The pdf is password protected and cannot be processed',
  UnprocessableEntity = 'Unprocessable Entity',
  UnknownError = 'Unknown error',
  DuplicateNameDetected = 'This name already exists, please try another one',
  UnknownErrorInMSS = 'Unknown error in Malware Scanner Service',
  OtherError = 'Other error',
  DocumentDuplicate = 'The document sent for submitting was declared as duplicate by ACH',
  ClauseDuplicate = 'Duplicate clause. This clause including its metadata already exists in GWR. Click here to check the clause.',
  ProductionDuplicateDraftClause = 'Production duplicate clause. Metadata of an existing clause has NOT been extended. Click here to check the clause.',
  ClauseDuplicateIncompatibleLanguage = 'Duplicate clause. Metadata cannot be extended due to incompatible language. Click here to check the clause.',
  ClauseDuplicateMetadataExtended = 'Duplicate clause. Metadata of an existing clause has been extended. Click here to check the clause.',
  ClauseAutomatedExtractMissingTag = 'Clause rejected during fully automated extract of clauses due to missing tag.',
  UnknownErrorACH = 'Unknown error',
  ProviderMismatch = 'Provider mismatch',
  NoClauseEditPermissions = 'User does not have sufficient classification rights to edit given clause',
  NoPermissionsToDeleteAllMetadata = 'Deleting all instances of given metadata is not allowed',
  MetadataMismatch = 'Metadata mismatch',
  ResubmittedContentNotPresentInClause = 'Resubmitted content is not present in clause',
  TagNameAlreadyExists = 'Provided tag name already exists.',
  TagNameDoesNotExist = 'Provided tag name does not exist.',
  ClauseContentChanged = 'Text editing available only for special characters. User is not allowed to edit a content of a clause.',
}
