import { MetadataDictionary } from '../../../store/files/upload/list.service.types';
import { BOOLEAN_VALUES } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { ClauseStatuses } from '../../../store/files/clauses/clauses.list.types';
import { Control, UseFormWatch } from 'react-hook-form';
import { ClauseMetadata } from '../../UploadClause/UploadClause.types';

export interface ValidateFormData {
  Approved: string;
  ApprovalComment: string;
  ClauseStatus: string;
  Remarks: string;
}

export enum VALIDATE_FORM_LABELS {
  STATE = 'Validation state',
  STATE_COMMENT = 'Validation comment',
  STATUS = 'Clause status',
  STATUS_COMMENT = 'Remarks',
}

export const StateDictionary: MetadataDictionary = {
  required: true,
  values: [
    { label: 'Approve', value: BOOLEAN_VALUES.TRUE },
    { label: 'Decline', value: BOOLEAN_VALUES.FALSE },
  ],
};

export const ClauseStatusDictionary: MetadataDictionary = {
  required: false,
  values: [
    { label: ClauseStatuses.BEST_PRACTICE, value: ClauseStatuses.BEST_PRACTICE },
    { label: ClauseStatuses.INVALID, value: ClauseStatuses.INVALID },
  ],
};

export type ApprovalValidationFieldsControl = Control<ValidateFormData | ClauseMetadata>;
export type ApprovalValidationFieldsWatch = UseFormWatch<ValidateFormData | ClauseMetadata>;

export interface ApprovalValidationFieldsProps {
  control: ApprovalValidationFieldsControl;
  watch: ApprovalValidationFieldsWatch;
  validateReasonField?: boolean;
  trigger?: () => void;
}
